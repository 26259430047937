var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","elevate-on-scroll":"","fixed":"","color":_vm.navBarColor},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndDown)?{key:"extension",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndDown && (_vm.isServer || _vm.isAuthorized))?_c('Links'):_vm._e()]},proxy:true}:null],null,true)},[_c('router-link',{attrs:{"to":_vm.isServer || _vm.isAuthorized
        ? {
            name: 'dashboard',
            params: {
              tenant: _vm.slug
            }
          }
        : {
            name: 'access-denied'
          },"exact":""}},[_c('v-btn',{attrs:{"icon":"","x-large":_vm.$vuetify.breakpoint.smAndUp,"large":_vm.$vuetify.breakpoint.xsOnly}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logos/logomark-white.svg"),"alt":"The Prefect Logo"}})])],1),(_vm.isServer || _vm.isAuthorized)?_c('TeamSideNavButton'):_vm._e(),_c('v-divider',{staticClass:"white vertical-divider my-auto mx-2",attrs:{"vertical":""}}),(!_vm.$vuetify.breakpoint.mdAndDown && (_vm.isServer || _vm.isAuthorized))?_c('Links'):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndUp && (_vm.isServer || _vm.isAuthorized))?_c('GlobalSearch'):_vm._e(),_c('HelpMenu'),(_vm.isServer || _vm.isAuthorized)?_c('NotificationMenu'):_vm._e(),_c('ConnectionMenu'),(_vm.isCloud)?_c('UserMenu'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }