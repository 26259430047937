var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]},[_c('v-slide-y-reverse-transition',[(_vm.visible)?_c('v-footer',{class:{
        'sm-and-down-left-padding': _vm.inset && _vm.$vuetify.breakpoint.smAndDown,
        'sm-and-up-left-padding': _vm.inset && _vm.$vuetify.breakpoint.smAndUp,
        'sm-and-down-bottom-padding': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"color":"transparent","padless":"","absolute":"","inset":_vm.inset}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-divider',{staticClass:"mx-auto",staticStyle:{"max-width":"1440px"}}),_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"block d-flex flex-column align-end justify-start"},[_c('div',_vm._l((_vm.socialIconsLeft),function(icon,i){return _c('v-btn',{key:i,class:_vm.inset ? 'ml-2' : 'ml-4',attrs:{"href":icon.link,"target":"_blank","depressed":"","icon":""}},[_c('v-icon',[_vm._v(_vm._s(icon.icon))])],1)}),1),_c('div',{staticClass:"mr-1 mt-4 d-flex w-100 flex-wrap",class:{
                'justify-space-between': _vm.$vuetify.breakpoint.smAndUp,
                'justify-end': _vm.$vuetify.breakpoint.xsOnly,
                'text-right': _vm.$vuetify.breakpoint.xsOnly
              }},_vm._l((_vm.linksLeft),function(link,i){return _c('div',{key:i,staticStyle:{"min-width":"66px"}},[_c('a',{staticClass:"link",attrs:{"href":link.link}},[_vm._v(" "+_vm._s(link.text)+" ")])])}),0)]),_c('div',{staticClass:"text-center",class:{
              'mx-16': _vm.$vuetify.breakpoint.mdAndUp,
              'mx-2': _vm.$vuetify.breakpoint.smAndDown
            }},[_c('div',{staticClass:"text-overline"},[_vm._v("Made with ♡ in DC")]),_c('a',{attrs:{"href":"https://prefect.io","target":"_blank"}},[_c('img',{staticClass:"logo mt-2",attrs:{"src":require("@/assets/logos/logomark-cerulean.svg"),"alt":"The Prefect Logo"}})])]),_c('div',{staticClass:"block d-flex flex-column align-start justify-start"},[_c('div',_vm._l((_vm.socialIconsRight),function(icon,i){return _c('v-btn',{key:i,class:_vm.inset ? 'mr-2' : 'mr-4',attrs:{"href":icon.link,"target":"_blank","depressed":"","icon":""}},[_c('v-icon',[_vm._v(_vm._s(icon.icon))])],1)}),1),_c('div',{staticClass:"ml-1 mt-4 d-flex w-100 flex-wrap"},_vm._l((_vm.linksRight),function(link,i){return _c('div',{key:i,class:{
                  'justify-space-between': _vm.$vuetify.breakpoint.smAndUp,
                  'justify-start': _vm.$vuetify.breakpoint.xsOnly,
                  'text-left': _vm.$vuetify.breakpoint.xsOnly
                },staticStyle:{"min-width":"66px"}},[_c('a',{staticClass:"link",attrs:{"href":link.link}},[_vm._v(" "+_vm._s(link.text)+" ")])])}),0)])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }